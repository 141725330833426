<template>
	<div class="change-box">
		<div class="content-title">商户信息</div>
		<template v-if="baseInfo">
			<el-row>
				<el-col :span="6" class="item-title">商户全称</el-col>
				<el-col :span="6" class="item-value">{{
					baseInfo.merSubName
				}}</el-col>
			</el-row>
			<el-row>
				<el-col :span="6" class="item-title">联系人姓名</el-col>
				<el-col :span="6" class="item-value">{{
					baseInfo.contactName
				}}</el-col>
				<el-col :span="4" class="item-title">联系人手机号</el-col>
				<el-col :span="6" class="item-value">{{
					baseInfo.mobileNo
				}}</el-col>
			</el-row>

			<el-row>
				<el-col :span="6" class="item-title">所属地区</el-col>
				<el-col :span="6" class="item-value"
					>{{ baseInfo.provinceZh }}{{ baseInfo.cityZh
					}}{{ baseInfo.areaZh }}</el-col
				>
				<el-col :span="4" class="item-title">商户状态</el-col>
				<el-col :span="6" class="item-value">{{
					baseInfo.status | auditStatusFM
				}}</el-col>
			</el-row>
			<template v-if="baseInfo.merType == '2'">
				<el-row>
					<el-col :span="6" class="item-title">营业执照名称</el-col>
					<el-col :span="6" class="item-value">{{
						businessInfo.companyName
					}}</el-col>
					<el-col :span="4" class="item-title">法人姓名</el-col>
					<el-col :span="6" class="item-value">{{
						businessInfo.legalName
					}}</el-col>
				</el-row>
				<el-row>
					<el-col :span="6" class="item-title">营业执照有效期</el-col>
					<el-col :span="6" class="item-value">{{
						businessInfo.busiLicensePeriod == "0"
							? "永久有效"
							: businessInfo.busiLicensePeriod
					}}</el-col>
				</el-row>
			</template>
			<!-- <el-row v-if="!isOrg">
                <el-col :span="6" class="item-title">开通时间</el-col>
                <el-col :span="6" class="item-value">{{userRegistrationInfo.registerTime}}</el-col>
            </el-row> -->
			<div class="content-title">结算信息</div>
			<el-row>
				<el-col :span="6" class="item-title">账户类型</el-col>
				<el-col :span="6" class="item-value">{{
					baseInfo.accountType | auditAccountTypeFM
				}}</el-col>
				<el-col :span="4" class="item-title">开户名</el-col>
				<el-col :span="6" class="item-value">{{
					baseInfo.accountName
				}}</el-col>
			</el-row>
			<el-row>
				<el-col :span="6" class="item-title">结算账号</el-col>
				<el-col :span="6" class="item-value">{{
					baseInfo.accountNo
				}}</el-col>
				<el-col :span="4" class="item-title">开户行</el-col>
				<el-col :span="6" class="item-value">{{
					baseInfo.bankName
				}}</el-col>
			</el-row>
			<el-row>
				<el-col :span="6" class="item-title">开户地区</el-col>
				<el-col :span="6" class="item-value"
					>{{ baseInfo.accountProvinceZh
					}}{{ baseInfo.accountCityZh }}</el-col
				>
				<el-col :span="4" class="item-title">开户支行</el-col>
				<el-col :span="6" class="item-value">{{
					baseInfo.branchName
				}}</el-col>
			</el-row>
			<el-row v-if="baseInfo.accountType == '2'">
				<el-col :span="6" class="item-title">结算人身份证号</el-col>
				<el-col :span="6" class="item-value">
					{{ maskIDNumber(baseInfo.settleIdCard) }}
				</el-col>
				<el-col :span="4" class="item-title">身份证有效期</el-col>
				<el-col :span="6" class="item-value">{{
					baseInfo.settleIdCardPeriod == "0"
						? "永久有效"
						: baseInfo.settleIdCardPeriod
				}}</el-col>
			</el-row>
			<el-row v-if="baseInfo.accountType != '2'">
				<el-col :span="6" class="item-title">结算人身份证号</el-col>
				<el-col :span="6" class="item-value"
					>{{ maskIDNumber(businessInfo.legalIdCard) }}
				</el-col>
				<el-col :span="4" class="item-title">身份证有效期</el-col>
				<el-col :span="6" class="item-value">{{
					businessInfo.legalCardPeriod == "0"
						? "永久有效"
						: businessInfo.legalCardPeriod
				}}</el-col>
			</el-row>
			<!-- <div class="content-title">费率信息</div>
            <table class="rate-table" style="width:950px" v-if="merRateList[0]">
                <tr>
                    <th>业务类型</th>
                    <th v-if="merRateList[0].defaultStatus!='1'">卡类型</th>
                    <th v-if="merRateList[0].defaultStatus!='1'">T1费率（%）</th>
                    <th>T0费率（%）</th>
                    <th v-if="merRateList[0].defaultStatus!='1'">T0提现费（元）</th>
                </tr>
                <tr v-for="item in merRateList" :key="item.id">
                    <td>{{item.payTypeCode | payType}}</td>
                    <td v-if="merRateList[0].defaultStatus!='1'">{{item.cardType | formateCardType}}</td>
                    <td v-if="merRateList[0].defaultStatus!='1'">
                        <div><span v-if="item.payTypeCode=='POS'&&item.cardType=='2'">比例：</span>{{item.t1Rate}}</div>
                        <div v-if="item.payTypeCode=='POS'&&item.cardType=='2'&&item.t1TopFee">封顶{{item.t1TopFee}}</div>
                    </td>
                    <td>
                            {{item.t0Rate}}
                    </td>
                    <td v-if="merRateList[0].defaultStatus!='1'"><div v-if="payTypeCode=='POS'&&item.cardType=='1'">{{item.cashFee}}</div> <span v-else>-</span></td>
                </tr>
            </table> -->
			<br />
		</template>
	</div>
</template>
<script>
import { MerchantApi } from "@/api";
import { mapState } from "vuex";
export default {
	data() {
		return {
			merchantNo: "",
			baseInfo: {},
			businessLicense: {},
			baseInfo: {},
			legalPerson: {},
			shopInfo: {},
			userRegistrationInfo: {},
			merRateList: [],
			businessInfo: [],
			state: ""
		};
	},
	computed: {
		...mapState("app", ["userInfo"]),
		isOrg() {
			return this.userInfo.org;
		}
	},
	created() {
		if (this.$route.query.merchantNo) {
			this.merchantNo = this.$route.query.merchantNo;
		}
		this.getDetail();
	},
	methods: {
		async getDetail() {
			let result = await MerchantApi.queryMerInfo(this.merchantNo);
			if (result.success) {
				this.baseInfo = result.data.baseInfo
					? result.data.baseInfo
					: {};
				this.businessInfo = result.data.businessInfo
					? result.data.businessInfo
					: {};
			}
		},
		// 身份证脱敏
		maskIDNumber(IDCode) {
			if (typeof IDCode === "string") {
				return IDCode.replace(/^(\d{6})\d+(\d{4})$/, "$1********$2");
			} else {
				return " ";
			}
		}
	}
};
</script>
